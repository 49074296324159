import { useState, useEffect } from 'react';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';

import { usePersonalInfoForm } from 'hook/usePersonalInfoForm';
import { useResponsive } from 'hook/useResponsive';
import { postAcb } from 'services/acbServices';
import { obterStatusDoPedido } from 'services/usuariosServices';
import { getVinculadosById } from 'services/vinculadoServices';
import formataCpf from 'util/formatarCPF';

import { Button } from 'components/Button';
import ModalAguardar from 'components/ModalAguardar';
import { ModalAvisoFinal } from 'components/ModalAvisoFinal';

import { useAuth } from 'context/Auth';
import { useContribuicao } from 'context/Contribuicao';
import { useInfoPessoais } from 'context/InfoPessoais';
import { useModal } from 'context/Modal';
import { useSteps } from 'context/Steps';
import { useToast } from 'context/Toast';
// import { useTributacao } from 'context/Tributacao';

import contribuicaoIcon from 'assets/images/contribuicao.svg';
import vinculadoIcon from 'assets/images/person_group.svg';
import personIcon from 'assets/images/person.svg';

// import tributacaoIcon from 'assets/images/tributacao.svg';
import { ConcluirInscricaoMobile } from './mobile';
import {
  Container,
  TitleContainer,
  CardContainer,
  Card,
  CardItem,
  Image,
  ContainerInformacoes,
  ContainerInformacoesText,
  EditarButton,
  ContainerDeclaracao,
  ContainerButton,
  // ContainerInformacoesRegimeText,
} from './styles';

export function ConcluirInscricao() {
  const { mobileLayout } = useResponsive();
  const { toast } = useToast();
  const { salario, porcentagem1, porcentagem2 } = useContribuicao();
  const { selectStep } = useSteps();
  const { formik, handleLoadFormulario, isLoadingFormulario } =
    usePersonalInfoForm();
  const {
    showModalAvisoFinal,
    setShowModalAvisoFinal,
    openModalAvisoFinal,
    openModalAguardar,
    closeModalAguardar,
  } = useModal();
  // const { handleLoadTributacao } = useTributacao();
  const { setPage } = useInfoPessoais();
  const { nome, cpf } = JSON.parse(sessionStorage.getItem('@user:dados'));
  const [checkedDeclaracao, setCheckedDeclaracao] = useState(false);
  const [vinculados, setVinculados] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setTravaCadastro } = useAuth();

  const handleChecked = () => {
    setCheckedDeclaracao(!checkedDeclaracao);
  };

  const handleLoadVinculados = async () => {
    const dados = sessionStorage.getItem('@user:dados');
    if (!dados) {
      toast.error('Dados não encontrados');
      return;
    }

    const { numPedido } = JSON.parse(dados);

    const getDados = await getVinculadosById(numPedido);

    if (!getDados) return;

    setVinculados(getDados);
  };

  const verificarSeTudoPreenchido = async () => {
    setLoading(true);

    const dados = sessionStorage.getItem('@user:dados');
    if (!dados) {
      toast.error('Dados não encontrados');
      return;
    }

    const { numPedido, numIdPf } = JSON.parse(dados);

    if (!formik.values.nome) {
      return toast.error('Nome não preenchido');
    }

    if (!formik.values.dataNascimento) {
      return toast.error('Data de nascimento não preenchida');
    }

    if (!formik.values.sexo) {
      return toast.error('Sexo não preenchido');
    }

    if (!formik.values.cpf) {
      return toast.error('CPF não preenchido');
    }

    if (!formik.values.celular) {
      return toast.error('Celular não preenchido', { id: 'toast' });
    }

    if (!formik.values.email) {
      return toast.error('E-mail não preenchido', { id: 'toast' });
    }

    if (!formik.values.matricula) {
      return toast.error('Matricula não preenchido', { id: 'toast' });
    }

    if (!formik.values.dataAdmissao) {
      return toast.error('Data Admissão não preenchido', { id: 'toast' });
    }

    if (!formik.values.cep) {
      return toast.error('CEP não preenchido', { id: 'toast' });
    }

    if (!formik.values.rua) {
      return toast.error('Logradouro não preenchido', { id: 'toast' });
    }

    if (!formik.values.bairro) {
      return toast.error('Bairro não preenchido', { id: 'toast' });
    }

    if (!formik.values.cidade) {
      return toast.error('Cidade não preenchido', { id: 'toast' });
    }

    if (!formik.values.estado) {
      return toast.error('Estado não preenchido', { id: 'toast' });
    }

    if (!formik.values.nacionalidade) {
      return toast.error('Nacionalidade não preenchido', { id: 'toast' });
    }

    if (!formik.values.estadoNacionalidade) {
      return toast.error('Nacionalidade não preenchido', { id: 'toast' });
    }

    if (!formik.values.numIdLocalidade) {
      return toast.error('Naturalidade não preenchido', { id: 'toast' });
    }

    if (formik.values.pessoaExposta == true) {
      if (!formik.values.orgaoPEP) {
        return toast.error('Orgão PEP não preenchido', { id: 'toast' });
      }

      if (!formik.values.cargoPEP) {
        return toast.error('Cargo PEP não preenchida', { id: 'toast' });
      }

      if (!formik.values.dataNomeacao) {
        return toast.error('Data de nomeação não preenchido', { id: 'toast' });
      }
    }

    if (salario === 0) {
      return toast.error('Salário não preenchido');
    }

    if (porcentagem1 === 0) {
      return toast.error('Contribuição básica não preenchido');
    }

    const body = {
      numPedido,
      numIdPf,
    };

    if (checkedDeclaracao) {
      const loading = toast.loading('Enviando dados...', { id: 'toast' });
      try {
        await postAcb(body);
        toast.success('Dados enviados com sucesso', { id: 'toast' });
        openModalAvisoFinal();
        setTravaCadastro(2);
      } catch (error) {
        if (error.response.status === 504) {
          openModalAguardar();
          const timer = setInterval(async () => {
            const data = await obterStatusDoPedido(numPedido);
            if (data.statusppPK.num_SEQ_STPSOLASS === 871) {
              clearInterval(timer);
              closeModalAguardar();
              setTravaCadastro(2);
              openModalAvisoFinal();
            }
          }, 3000);
        } else {
          const multipleMessages = Array.isArray(error.response?.data.erros);

          if (multipleMessages) {
            error.response?.data.erros.forEach((e) => {
              toast.error(e);
            });
          } else {
            toast.error(error.response?.data.message);
          }
        }
      } finally {
        toast.dismiss(loading);
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error('Você precisa aceitar as informações', { id: 'declaracao' });
    }
  };

  useEffect(() => {
    handleLoadVinculados();
    handleLoadFormulario();
  }, []);

  return (
    <>
      {!mobileLayout && (
        <>
          <Container>
            <TitleContainer>
              <h2>Confira os dados abaixo antes de concluir sua inscrição</h2>
            </TitleContainer>
            <CardContainer>
              <Card>
                <CardItem>
                  <Image src={personIcon} alt="Pessoa" />

                  <ContainerInformacoes>
                    <h3>Informações pessoais</h3>
                    <ContainerInformacoesText>
                      {nome}
                      <br />
                      {formataCpf(cpf)}
                    </ContainerInformacoesText>

                    <EditarButton
                      onClick={() => {
                        setPage('formulario');
                        selectStep(0);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem>
                <CardItem>
                  <Image src={contribuicaoIcon} alt="Contribuição" />

                  <ContainerInformacoes>
                    <h3>Contribuição</h3>
                    <ContainerInformacoesText>
                      Básica: {porcentagem1}%
                      <br />
                      Voluntária: {porcentagem2}%
                    </ContainerInformacoesText>

                    <EditarButton
                      onClick={() => {
                        setPage('contribuicao');
                        selectStep(1);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem>
              </Card>
              <Card>
                <CardItem>
                  <Image src={vinculadoIcon} alt="Vinculados" />

                  <ContainerInformacoes>
                    <h3>Vinculados</h3>
                    <ContainerInformacoesText>
                      {vinculados.map((vinculado) => (
                        <p> {vinculado.nome} </p>
                      ))}
                    </ContainerInformacoesText>
                    <EditarButton
                      onClick={() => {
                        selectStep(1);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem>
                {/* <CardItem>
                  <Image src={tributacaoIcon} alt="Tributação" />

                  <ContainerInformacoes>
                    <h3>Tributação</h3>
                    <ContainerInformacoesRegimeText style={{ width: '90px' }}>
                      {verificarRegime()}
                    </ContainerInformacoesRegimeText>

                    <EditarButton
                      onClick={() => {
                        selectStep(3);
                      }}
                    >
                      Editar
                    </EditarButton>
                  </ContainerInformacoes>
                </CardItem> */}
              </Card>
            </CardContainer>
            <ContainerDeclaracao>
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                checked={checkedDeclaracao}
                onChange={() => {
                  handleChecked();
                }}
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <div>
                Declaro, para os devidos fins, que as informações prestadas
                acima são verdadeiras e assumo inteira responsabilidade pelas
                mesmas.
              </div>
            </ContainerDeclaracao>

            <ContainerButton>
              <Button
                style={{ color: '#fff' }}
                normal
                onClick={() => {
                  // Validação de todos os dados
                  verificarSeTudoPreenchido();
                }}
                disabled={loading || isLoadingFormulario}
              >
                Enviar pedido de inscrição
              </Button>
            </ContainerButton>
          </Container>

          <ModalAvisoFinal
            showModal={showModalAvisoFinal}
            setShowModal={setShowModalAvisoFinal}
          />

          <ModalAguardar />
        </>
      )}

      {mobileLayout && <ConcluirInscricaoMobile />}
    </>
  );
}
