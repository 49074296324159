import { useState } from 'react';

import { subYears } from 'date-fns';
import { useFormik } from 'formik';
import moment from 'moment';
import { pathCadastro } from 'services/cadastroServices';
// import { getCidades } from 'services/ibgeService';
import { getEstados, getCidades } from 'services/localidadeServices';
// import { getTravarUser } from 'services/loginServices';
import { getOneUser, buscarPorCEP } from 'services/usuariosServices';
import calculaIdade from 'util/calcularIdade';
import formataCpf from 'util/formatarCPF';
import formatarNome from 'util/formatarNome';
import formataTelefone from 'util/formatarTelefone';
import * as yup from 'yup';

import { useAuth } from 'context/Auth';
import { useSteps } from 'context/Steps';
import { useToast } from 'context/Toast';

export function usePersonalInfoForm() {
  const { toast } = useToast();
  const { travaCadastro } = useAuth();
  const { handleNext } = useSteps();

  const [isLoadingFormulario, setIsLoadingFormulario] = useState(false);
  const [dadosApi, setDadosApi] = useState(null);
  const [estadoNacionalidadeLista, setEstadoNacionalidadeLista] = useState([]);
  const [cidadeLista, setCidadeLista] = useState([]);
  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingAvancar, setLoadingAvancar] = useState(false);

  yup.addMethod(
    yup.mixed,
    'isRequiredByPessoaExposta',
    function (errorMessage) {
      return this.test(
        'is-required-by-pessoa-exposta',
        'Campo obrigatório',
        function (value) {
          const { path, createError } = this;

          if (!formik.values.pessoaExposta) {
            return true;
          }
          return value ? true : createError({ path, message: errorMessage });
        }
      );
    }
  );

  yup.addMethod(yup.date, 'isMaioridade', function (errorMessage) {
    return this.test('is-maioridade', 'Deve ser maior de idade', function () {
      const { path, createError } = this;
      const data = moment(formik.values.dataNascimento).format('DD/MM/YYYY');
      const idade = calculaIdade(data);
      if (idade > 18) {
        return true;
      }
      return createError({ path, message: errorMessage });
    });
  });

  const validationSchema = yup.object({
    nome: yup
      .string()
      .required('Nome é obrigatório')
      .matches(/^([a-zA-Z\u00C0-\u00FF´']+\s)+[a-zA-Z\u00C0-\u00FF´']+$/, {
        message: 'Nome inválido',
      }),
    dataNascimento: yup
      .date()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Data inválida')
      .test({
        test: (value) => value <= subYears(new Date(), 18),
        message: 'Deve ser maior de idade',
      }),
    sexo: yup.string().required('Campo obrigatório'),
    cpf: yup.string().required('Campo obrigatório').length(14, 'CPF inválido'),
    celular: yup
      .string()
      .required('Campo obrigatório')
      .length(15, 'Celular inválido'),
    email: yup
      .string()
      .required('E-mail é obrigatório')
      .email('E-mail inválido'),
    matricula: yup.string().required('Matrícula é obrigatório'),
    dataAdmissao: yup
      .date()
      .nullable()
      .required('Data de admissão é obrigatório')
      .min(yup.ref('dataNascimento'), 'Data de admissão menor que nascimento')
      .max(new Date(), 'Data de adimissão não pode ser maior que a data atual')
      .typeError('Data de admissão inválida'),
    cep: yup.string().required('CEP é obrigatório').length(8, 'CEP inválido'),
    rua: yup
      .string()
      .required('Logradouro é obrigatório')
      .matches(/^[A-Za-z0-9\u00C0-\u00FF \-()]+$/, {
        message: 'Logradouro inválido',
      }),
    bairro: yup
      .string()
      .required('Bairro é obrigatório')
      .matches(/^[A-Za-z0-9\u00C0-\u00FF \-()]+$/, {
        message: 'Bairro inválido',
      }),
    cidade: yup
      .string()
      .required('Cidade é obrigatório')
      .matches(/^[A-Za-z\u00C0-\u00FF \-()]+$/, {
        message: 'Cidade inválida',
      }),
    estado: yup
      .string()
      .required('Estado é obrigatório')
      .matches(/^[A-Z]{2}$/, {
        message: 'Estado inválido',
      }),
    numero: yup.string().required('Número é obrigatório'),
    complemento: yup.string().matches(/^[a-zA-Z0-9 ]+$/, {
      message: 'Complemento inválido',
    }),
    nacionalidade: yup.string().required('Nacionalidade é obrigatório'),
    estadoNacionalidade: yup.string().when('nacionalidade', {
      is: 'BRA',
      then: (schema) => schema.required('Naturalidade Estado é obrigatório'),
      otherwise: (schema) => schema.notRequired(),
    }),
    numIdLocalidade: yup.string().when('nacionalidade', {
      is: 'BRA',
      then: (schema) => schema.required('Naturalidade Município é obrigatório'),
      otherwise: (schema) => schema.notRequired(),
    }),
    // naturalidade: yup.string().required('Campo obrigatório'),
    pessoaExposta: yup.boolean(),
    orgaoPEP: yup.string().when('pessoaExposta', {
      is: true,
      then: (schema) =>
        schema
          .required('Órgão é obrigatório')
          .matches(/^[A-Za-z0-9\u00C0-\u00FF ]+$/, {
            message: 'Órgão inválido',
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    cargoPEP: yup.string().when('pessoaExposta', {
      is: true,
      then: (schema) =>
        schema
          .required('Cargo é obrigatório')
          .matches(/^[A-Za-z0-9\u00C0-\u00FF ]+$/, {
            message: 'Cargo inválido',
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    dataNomeacao: yup
      .date()
      .nullable()
      .max(new Date())
      .when('pessoaExposta', {
        is: true,
        then: (schema) =>
          schema
            .required('Data de nomeação é obrigatório')
            .test(
              'dataNomeacao',
              'Data de nomeação deve ser maior ou igual a 18 anos após a data de nascimento',
              function (value) {
                const { dataNascimento } = this.parent;
                const dataMinima = new Date(dataNascimento);
                dataMinima.setFullYear(dataMinima.getFullYear() + 18);
                return value >= dataMinima;
              }
            ),
        otherwise: (schema) => schema.notRequired(),
      })
      .typeError('Data de nomeação inválida'),
    dataExoneracao: yup
      .date()
      .nullable()
      .min(
        yup.ref('dataNomeacao'),
        'Data de exoneração menor que data de nomeação'
      )
      .max(new Date())
      .typeError('Data de exoneração inválida'),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
      dataNascimento: null,
      sexo: '',
      cpf: '',
      celular: '',
      email: '',
      matricula: '',
      dataAdmissao: null,
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
      nacionalidade: '',
      estadoNacionalidade: '',
      naturalidade: '',
      numIdLocalidade: '',
      pessoaExposta: false,
      orgaoPEP: '',
      cargoPEP: '',
      dataNomeacao: null,
      dataExoneracao: null,
      termos: false,
    },
    validationSchema,
    onSubmit: async ({
      nome,
      dataNascimento,
      sexo,
      cpf,
      celular,
      email,
      matricula,
      dataAdmissao,
      rua,
      numero,
      complemento,
      bairro,
      cidade,
      estado,
      cep,
      nacionalidade,
      estadoNacionalidade,
      naturalidade,
      numIdLocalidade,
      pessoaExposta,
      orgaoPEP,
      cargoPEP,
      dataNomeacao,
      dataExoneracao,
      termos,
    }) => {
      try {
        setLoadingAvancar(true);
        if (!termos) throw new Error('Aceite os termos, para continuar.');

        /* if (dataNomeacao != null) {
           if (dataNomeacao <= dataNascimento)
             throw new Error('Data de exoneração menor que a data de nomeação');
         }

         if (dataNomeacao != null) {
           if (dataNomeacao <= dataAdmissao)
             throw new Error('Data de nomeação menor que data de admissão');
         } */

        if (rua == null) throw new Error('O Campo rua/avenida não preenchido.');
        if (bairro == null) throw new Error('O Campo bairro não preenchido.');
        if (cidade == null) throw new Error('O Campo Cidade não preenchido.');
        if (estado == null) throw new Error('O Campo Estado não preenchido.');
        if (cep == null) throw new Error('O campo CEP não preenchido.');
        if (nacionalidade !== 'BRA') {
          estadoNacionalidade = null;
          numIdLocalidade = null;
        }

        if (dataExoneracao === '') {
          dataExoneracao = null;
        }

        if (dataAdmissao <= dataNascimento) {
          throw new Error(
            'Data de admissão não pode ser menor/igual que a data de nascimento'
          );
        }

        naturalidade = getNaturalidade(estadoNacionalidade);

        if (pessoaExposta == false) {
          const { numPedido, numIdPf } = JSON.parse(
            sessionStorage.getItem('@user:dados')
          );

          const userData = {
            numPedido,
            numIdPf,
            nome,
            dtNascimento: dataNascimento,
            sexo: sexo === 'Masculino' ? 'M' : 'F',
            cpf: formataCpf(cpf),
            celular: celular.replace(/\D/g, ''),
            email,
            numMatricula: matricula,
            dtAdmissao: dataAdmissao,
            rua,
            numero,
            bairro,
            cidade,
            estado,
            cep,
            complemento,
            nacionalidade,
            estadoNacionalidade,
            naturalidade,
            numIdLocalidade,
            // pessoaExposta: pessoaExposta == false ? 'N' : 'S',
            orgaoPep: null,
            cargoPep: null,
            dtNomeacao: null,
            dtExoneracao: null,
          };

          await pathCadastro(userData);

          sessionStorage.setItem('@user:dados', JSON.stringify(userData));

          toast.success('Dados salvos com sucesso!', {
            id: 'toast-personal-info',
          });

          await handleNext();
        } else if (pessoaExposta == true) {
          const { numPedido, numIdPf } = JSON.parse(
            sessionStorage.getItem('@user:dados')
          );

          if (!cargoPEP) throw new Error('Cargo PEP obrigatório');
          if (!orgaoPEP) throw new Error('Orgão PEP obrigatório');
          if (!dataNomeacao) throw new Error('Data de nomeação obrigatório');

          if (dataNomeacao > new Date()) {
            throw new Error('Data de nomeação maior que data atual');
          } else if (dataNomeacao < dataNascimento) {
            throw new Error('Data de nomeação menor que a data de nascimento');
          } else if (dataExoneracao != null) {
            if (dataExoneracao < dataNomeacao) {
              throw new Error('Data de exoneração menor que data de nomeação.');
            } else if (
              moment(dataExoneracao).format('DD/MM/YYYY') ==
              moment(dataNomeacao).format('DD/MM/YYYY')
            ) {
              throw new Error('Data de nomeação igual a data de exoneração.');
            }
          }

          const userData = {
            numPedido,
            numIdPf,
            nome,
            dtNascimento: dataNascimento,
            sexo: sexo === 'Masculino' ? 'M' : 'F',
            cpf: formataCpf(cpf),
            celular: celular.replace(/\D/g, ''),
            email,
            numMatricula: matricula,
            dtAdmissao: dataAdmissao,
            rua,
            numero,
            bairro,
            cidade,
            estado,
            cep,
            complemento,
            nacionalidade,
            estadoNacionalidade,
            naturalidade,
            numIdLocalidade,
            // pessoaExposta: pessoaExposta == false ? 'N' : 'S',
            orgaoPep: orgaoPEP,
            cargoPep: cargoPEP,
            dtNomeacao: dataNomeacao,
            dtExoneracao: dataExoneracao,
          };

          await pathCadastro(userData);

          sessionStorage.setItem('@user:dados', JSON.stringify(userData));

          toast.success('Dados salvos com sucesso!', {
            id: 'toast-personal-info',
          });

          await handleNext();
        }
      } catch (error) {
        if (error.isAxiosError) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      } finally {
        setLoadingAvancar(false);
      }
    },
  });

  const getNaturalidade = function (estadoNacionalidade) {
    const naturalidade = estadoNacionalidadeLista.filter(
      (estado) => estado.sigla == estadoNacionalidade
    );
    return naturalidade[0]?.nome ?? 'Estrangeiro';
  };

  const handleLoadFormulario = async () => {
    const loading = toast.loading('Carregando formulário');
    setIsLoadingFormulario(true);

    await handleLoadNaciolidadeLista();

    const dados = sessionStorage.getItem('@user:dados');

    if (!dados) {
      toast.error('Dados não encontrados');
      return;
    }

    const { numPedido, numIdPf } = JSON.parse(dados);

    if (!numPedido || !numIdPf) return;

    // const { travarCadastro } = await getTravarUser(numPedido, numIdPf);

    // setTravaCadastro(travarCadastro);

    const getDados = await getOneUser(numPedido, numIdPf);

    if (!getDados) throw new Error('Nenhum registro encontrado');
    formik.setFieldValue('nome', formatarNome(getDados.nome));

    if (
      getDados.dtNascimento == 'Invalid date' ||
      getDados.dtNascimento == null ||
      getDados.dtNascimento == '' ||
      getDados.dtNascimento == undefined
    ) {
      formik.setFieldValue('dataNascimento', null);
    } else {
      formik.setFieldValue('dataNascimento', new Date(getDados.dtNascimento));
    }

    if (getDados.sexo === 'M') {
      formik.setFieldValue('sexo', 'Masculino');
    } else if (getDados.sexo === 'F') {
      formik.setFieldValue('sexo', 'Feminino');
    }
    formik.setFieldValue('cpf', formataCpf(getDados.cpf));
    formik.setFieldValue('celular', formataTelefone(getDados.celular));
    formik.setFieldValue('email', getDados.email);
    formik.setFieldValue('matricula', getDados.numMatricula);

    if (
      getDados.dtAdmissao == 'Invalid date' ||
      getDados.dtAdmissao == null ||
      getDados.dtAdmissao == '' ||
      getDados.dtAdmissao == undefined
    ) {
      formik.setFieldValue('dataAdmissao', null);
    } else {
      formik.setFieldValue('dataAdmissao', new Date(getDados.dtAdmissao));
    }

    if (getDados.estadoNacionalidade) {
      formik.setFieldValue('estadoNacionalidade', getDados.estadoNacionalidade);
      await handleLoadCidadeLista(getDados.estadoNacionalidade);
    }
    formik.setFieldValue('numero', getDados.numero);
    formik.setFieldValue('complemento', getDados.complemento);
    formik.setFieldValue('cep', getDados.cep);
    formik.setFieldValue('rua', getDados.rua);
    formik.setFieldValue('bairro', getDados.bairro);
    formik.setFieldValue('cidade', getDados.cidade);
    formik.setFieldValue('estado', getDados.estado);
    formik.setFieldValue('nacionalidade', getDados.nacionalidade);
    formik.setFieldValue('naturalidade', getDados.naturalidade);
    formik.setFieldValue('numIdLocalidade', getDados.idNaturalidade);

    if (getDados.pessoaExposta === 'S') {
      formik.setFieldValue('pessoaExposta', true);
    } else if (getDados.pessoaExposta === 'N') {
      formik.setFieldValue('pessoaExposta', false);
    }
    if (getDados.orgaoPep === null) {
      formik.setFieldValue('orgaoPEP', '');
    } else {
      formik.setFieldValue('orgaoPEP', getDados.orgaoPep);
    }
    if (getDados.cargoPep === null) {
      formik.setFieldValue('cargoPEP', '');
    } else {
      formik.setFieldValue('cargoPEP', getDados.cargoPep);
    }
    formik.setFieldValue('dataNomeacao', new Date(getDados.dtNomeacao));

    if (
      getDados.dtNomeacao == 'Invalid date' ||
      getDados.dtNomeacao == null ||
      getDados.dtNomeacao == '' ||
      getDados.dtNomeacao == undefined
    ) {
      formik.setFieldValue('dataNomeacao', null);
    } else {
      formik.setFieldValue('dataNomeacao', new Date(getDados.dtNomeacao));
    }

    if (
      getDados.dtExoneracao == 'Invalid date' ||
      getDados.dtExoneracao == null ||
      getDados.dtExoneracao == '' ||
      getDados.dtExoneracao == undefined
    ) {
      formik.setFieldValue('dataExoneracao', null);
    } else {
      formik.setFieldValue('dataExoneracao', new Date(getDados.dtExoneracao));
    }

    if (travaCadastro == 1 || travaCadastro == 2) {
      formik.setFieldValue('termos', true);
    }

    setDadosApi(getDados);

    setIsLoadingFormulario(false);
    toast.dismiss(loading);

    return getDados;
  };

  const handleCep = async (cep) => {
    setLoadingCep(true);
    try {
      const result = await buscarPorCEP(cep);
      formik.setFieldValue('rua', result.logradouro);
      formik.setFieldValue('bairro', result.bairro.substr(0, 30));
      formik.setFieldValue('cidade', result.localidade);
      formik.setFieldValue('estado', result.uf);
    } catch (error) {
      if (error.isAxiosError) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Não foi possível consultar o CEP');
      }
    }
    setLoadingCep(false);
  };

  const isDisabledCpf = () => {
    if ((Boolean(dadosApi) && !dadosApi.cpf) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledEmail = () => {
    if ((Boolean(dadosApi) && !dadosApi.email) || isLoadingFormulario) {
      return false;
    }
    return true;
  };
  const isDisabledSexo = () => {
    if ((Boolean(dadosApi) && !dadosApi.sexo) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledMatricula = () => {
    if ((Boolean(dadosApi) && !dadosApi.numMatricula) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledDtAdmisao = () => {
    if ((Boolean(dadosApi) && !dadosApi.dtAdmissao) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledDtNasc = () => {
    if ((Boolean(dadosApi) && !dadosApi.dtNascimento) || isLoadingFormulario) {
      return false;
    }
    return true;
  };

  const isDisabledAll = () => {
    if (travaCadastro == 2 || isLoadingFormulario) {
      return true;
    }
    return false;
  };

  const isDisabledForm = () => {
    if (travaCadastro == 1 || isLoadingFormulario) {
      return true;
    }
    return false;
  };

  const naoTrava = () => {
    if (travaCadastro == 0 || travaCadastro == null) {
      return false;
    }
    return true;
  };

  const handleLoadNaciolidadeLista = async () => {
    const result = await getEstados('BRA');
    setEstadoNacionalidadeLista(result);
  };

  const handleLoadCidadeLista = async (uf) => {
    const result = await getCidades(uf);
    setCidadeLista(result);
  };

  return {
    formik,
    handleLoadFormulario,
    disabled_cpf: isDisabledCpf(),
    disabled_sexo: isDisabledSexo(),
    disabled_matricula: isDisabledMatricula(),

    disabled_email: isDisabledEmail(),
    disabled_dtAdmissao: isDisabledDtAdmisao(),
    disabled_dtnasc: isDisabledDtNasc(),
    disabledAll: isDisabledAll(),
    disabledForm: isDisabledForm(),
    naoDesabilita: naoTrava(),

    handleCep,
    estadoNacionalidadeLista,
    cidadeLista,
    handleLoadCidadeLista,
    loadingCep,
    isLoadingFormulario,
    loadingAvancar,
  };
}
