// import { getTravarUser } from 'services/loginServices';

import { ConcluirInscricao } from 'pages/ConcluirInscricao';
import { Status } from 'pages/Status';

import { useAuth } from 'context/Auth';

export function StatusEConfirmacao() {
  const { travaCadastro } = useAuth();

  return <>{travaCadastro == 2 ? <Status /> : <ConcluirInscricao />}</>;
}
